import { useInfiniteQuery, useQuery } from "@tanstack/react-query";

import axios from "axios";
import { Model, ModelData, ModelList, ModelListData } from "../utils/interfaces/ModelResponse";
import { baseURL, results_per_page } from '../constants';
import { getTokenFromCookie } from "./AuthService";
import { shuffle } from "lodash";
import { filterUnVariantModels } from "../../assets/scripts/product/filter";

const getModel = async (model_uuid: string): Promise<Model> => {
    const { data } = await axios.get<Model>(
        baseURL + "/models/" + model_uuid,
        {
            headers: {
                "Authorization": `Bearer ${getTokenFromCookie()}`
            }
        }
    )
    return data;
}

export const useGetModel = (model_uuid: string) => {
    return useQuery<Model, Error>(["getModel", model_uuid], () => getModel(model_uuid), { enabled: false })
}


export const getBaseModels = async ({ pageParam = 1 }, category: string, optionalToken = ''): Promise<ModelList> => {
    if (category === "TRENDING") {
        const { data } = await axios.get<ModelList>(
            `${baseURL}/models?per_page=${11}&page=${pageParam}&status=ACTIVE`,
            {
                headers: {
                    "Authorization": `Bearer ${optionalToken || getTokenFromCookie()}`
                }
            });

        let filteredProducts = filterUnVariantModels(data.data);

        // Control first model price to prevent undefined price
        while (filteredProducts[0].variants[0].price === 0 && filteredProducts.some(model => { return model.variants[0].price > 0 })) {
            filteredProducts.push(filteredProducts.shift() as ModelListData);
        }
        const firstItem = filteredProducts.shift();
        filteredProducts = shuffle(filteredProducts);
        filteredProducts.unshift(firstItem as ModelListData);
        
        return {
            ...data,
            data: filteredProducts
        } ;
    } else {
        const { data } = await axios.get(
            `${baseURL}/models?per_page=${results_per_page}&page=${pageParam}&category=${category}&status=ACTIVE`,
            {
                headers: {
                    "Authorization": `Bearer ${optionalToken || getTokenFromCookie()}`
                }
            });
        return data;
    }
}

export const useGetModels = (category: string) => {
    return useInfiniteQuery<ModelList, Error>(
        ['models'],
        ({ pageParam = 1 }) => getBaseModels({ pageParam }, category),
        {
            getNextPageParam: (pages) => {
                if (pages.data.length > 0) {
                    return pages.page + 1
                } else {
                    return undefined
                }
            },
        }
    )
}