import React, { useEffect } from 'react'
import Lottie from 'lottie-react';
import TryoAnim from "../assets/animations/TryoAnim.json";
import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { getVariantModel, getVariantModelListByBaseModelId, useGetVariantModelsByCategory } from '../shared/query-hooks/VariantModelService';
import { getBaseModels, useGetModels } from '../shared/query-hooks/ModelService';
import { useQuery } from "@tanstack/react-query";
import type { NextPage } from 'next';
import { useRouter } from 'next/router'
import Head from 'next/head';
import Dynamic100Vh from '../components/DynamicDiv100vh/Div100Vh';

const Home: NextPage = () => {
    const router = useRouter()
    //const {data: variantModelsData, status: variantModelsStatus} = useGetVariantModelsByCategory("TRENDING")
    //const {data: baseModelData, status: baseModelStatus} = useGetModels();

    const { data: baseModelListData } = useQuery(['baseModelList', [{}, "TRENDING"]], () => getBaseModels({ pageParam: 1 }, "TRENDING"));
    //const {data: baseModelListData} = getBaseModels({pageParam: 1}, "TRENDING")
    const baseModelId = baseModelListData?.data[0]._id;

    const { data: variantModelListData } = useQuery(['variantModelList', baseModelId], () => getVariantModelListByBaseModelId(baseModelId!), {
        enabled: !!baseModelId
    })
    const variantModelId = variantModelListData?.data[0]._id;

    const { data: variantModelData, status: variantModelDataStatus } = useQuery(['variantModel', variantModelId], () => getVariantModel(variantModelId!), {
        enabled: !!variantModelId
    })

    const style = {
        width: 300
    }

    const [progress, setProgress] = React.useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = 20;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        if (variantModelData) {
            setProgress(100);
            setTimeout(() => {
                //navigate(/products/trending/${variantModelsData.data[0].brand_slug}/${variantModelsData.data[0].base_model_slug}/${variantModelsData.data[0].variant_model_slug});
                //navigate(/products/trending/${variantModelsData.data[0].brand_detail[0].brand_slug}/${variantModelsData.data[0].variant_model_slug});
                console.log("fetched variant model, can navigate");
                router.push(`/products/trending/${variantModelData.data[0].brand_detail[0].brand_slug}/${variantModelData.data[0].base_model_detail[0].model_slug}/${variantModelData.data[0].variant_model_slug}`)
            }, 500);
        }

        return () => {
            clearInterval(timer);
        };
    }, [variantModelData]);

    const options = {
        animationData: TryoAnim,
        loop: true,
        autoplay: true,
    };

    return (
        <>
            <Head>
                <title>TRYO</title>
                <meta name="description" content="Realtime Virtual Try-On Experiences" />
            </Head>
            <Dynamic100Vh style={{ width: "100%" }}>
                <Grid sx={{ background: "black", height: "100%" }} container direction="column" justifyContent="center" alignItems="center" spacing={0}>
                    <Grid item container justifyContent="center">
                        <Lottie style={style} {...options}></Lottie>
                    </Grid>
                    <Grid item container justifyContent="center" marginTop={3}>
                        <Box sx={{ width: '149px' }}>
                            <LinearProgress sx={{ borderRadius: "100px" }} variant="determinate" color='secondary' value={progress} />
                        </Box>
                    </Grid>
                    <Grid item container justifyContent="center" marginTop={2}>
                        <Typography color="white" variant='customBodySm'>EYE IT. TRY IT. BUY IT.</Typography>
                    </Grid>
                </Grid>
            </Dynamic100Vh>
        </>
    )
};

export default Home;