export const baseURL = process.env.NEXT_PUBLIC_BASE_API_URL;
export const searchStringLength = 3;
export const debouncedSearchDelay = 750;
export const results_per_page = 12;
export const searchURL = "https://y544a5tgjc.execute-api.us-east-1.amazonaws.com/development/models/search?search_query=";
export const IOSDownloadLink = process.env.NEXT_PUBLIC_IOS_DOWNLOAD_LINK;
export const AndroidDownloadLink = process.env.NEXT_PUBLIC_ANDROID_DOWNLOAD_LINK;
export const aboutURL = process.env.NEXT_PUBLIC_ABOUT_URL;
//static file urls
export const qrURL = process.env.NEXT_PUBLIC_QR_URL
export const logoURL = "https://dr335v8oixnhk.cloudfront.net/static/files/TRYO_LOGO.png"
export const IOSDownloadButtonSvg = "https://dr335v8oixnhk.cloudfront.net/static/files/IOSDownload.svg"