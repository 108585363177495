import { useQuery} from "@tanstack/react-query";
import axios from "axios";
import { Auth } from "../utils/interfaces/AuthResponse";
import { baseURL } from "../constants";
import { Cookies } from "react-cookie";

var CryptoJS = require("crypto-js");

const cookies = new Cookies();

const loginPost = async ():Promise<Auth> => {
    const {data} = await axios.post<Auth>(
        baseURL + "/auth/login",
        {
            //this is visible on payload section
            username: "ttb-web",
            password: "Ya#puF9lBH6gUD%z6GCPDqfEZon"
        }
    )
    return data;
}

export const useLoginPost = () => {
    return useQuery(['loginPost'], loginPost, {enabled: false});
}

export const getTokenFromCookie = () :string => {
    const token = cookies.get("authToken");
    const decrypted = CryptoJS.AES.decrypt(token,"qreal");
    const decrypted_token = decrypted.toString(CryptoJS.enc.Utf8);
    return decrypted_token;
}

export const decryptToken = (token: string) :string => {
    const decrypted = CryptoJS.AES.decrypt(token,"qreal");
    const decrypted_token = decrypted.toString(CryptoJS.enc.Utf8);
    return decrypted_token;
}