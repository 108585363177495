import { useInfiniteQuery, useQuery } from "@tanstack/react-query";

import axios from "axios";
import { VariantModel, VariantModelData, VariantModelList } from "../utils/interfaces/VariantModelResponse";
import { baseURL, results_per_page } from "../constants";
import { getTokenFromCookie } from "./AuthService";

export const getVariantModel = async (variant_model_uuid: string, optionalToken = ''): Promise<VariantModel> => {
    const { data } = await axios.get<VariantModel>(
        baseURL + "/variant-models/" + variant_model_uuid,
        {
            headers: {
                "Authorization": `Bearer ${optionalToken || getTokenFromCookie()}`
            }
        }
    )
    return data;
}

export const getVariantModelBySlug = async (variant_model_slug: string, model: string, optionalToken = ''): Promise<VariantModel> => {
    const { data } = await axios.get<VariantModel>(
        baseURL + `/variant-models/by-slug/${model}/${variant_model_slug}`,
        {
            headers: {
                "Authorization": `Bearer ${optionalToken || getTokenFromCookie()}`
            }
        }
    )
    return data;
}

const getVariantModels = async ({ pageParam = 1 }): Promise<VariantModelList> => {
    const { data } = await axios.get(
        `${baseURL}/variant-models?per_page=${results_per_page}&page=${pageParam}`,
        {
            headers: {
                "Authorization": `Bearer ${getTokenFromCookie()}`
            }
        });
    return data;
}

export const getAllVariantModels = async (optionalToken = ''): Promise<VariantModelList> => {
    const { data } = await axios.get(
        `${baseURL}/variant-models?list_all=true`,
        {
            headers: {
                "Authorization": `Bearer ${optionalToken || getTokenFromCookie()}`
            }
        });
    return data;
}

export const useVariantModel = (variant_model_uuid: string, customOptions = {}) => {
    return useQuery<VariantModel, Error>(["getVariantModel", variant_model_uuid], () => getVariantModel(variant_model_uuid), { enabled: !!variant_model_uuid, ...customOptions });
}

export const useVariantModelBySlug = (variant_model_uuid: string, model: string, customOptions = {}) => {
    return useQuery<VariantModel, Error>(["getVariantModel", variant_model_uuid], () => getVariantModelBySlug(variant_model_uuid, model), { enabled: !!variant_model_uuid, ...customOptions });
}

export const useVariantModels = () => {
    return useInfiniteQuery<VariantModelList, Error>(
        ['variantModels'],
        getVariantModels,
        {
            getNextPageParam: (pages) => {
                if (pages.data.length > 0) {
                    return pages.page + 1
                } else {
                    return undefined
                }
            },
        }
    )
}

export const getVariantModelListByBrandId = async ({ pageParam = 1 }, brand_id: string, optionalToken = ''): Promise<VariantModelList> => {
    const { data } = await axios.get(
        `${baseURL}/variant-models?per_page=${results_per_page}&page=${pageParam}&brand_id=${brand_id}&status=ACTIVE`, //62f21f44f5f58a51d2ab0bd1
        {
            headers: {
                "Authorization": `Bearer ${optionalToken || getTokenFromCookie()}`
            }
        });
    return data;
}

export const useVariantModelListByBrandId = (brand_id: string, initialData: VariantModelList) => {
    return useInfiniteQuery<VariantModelList, Error>(
        ['variantModelListByBrandId'],
        ({ pageParam = 1 }) => getVariantModelListByBrandId({ pageParam }, brand_id),
        {
            getNextPageParam: (pages) => {
                if (pages.data.length > 0) {
                    return pages.page + 1
                } else {
                    return undefined
                }
            },
            initialData: () => {
                const data = initialData
                if (data) {
                    return {
                        pageParams: [undefined],
                        pages: [data]
                    }
                }
            },
            enabled: !!brand_id,
            refetchOnMount: false,
        },
    )
}

export const getVariantModelListByCategory = async ({ pageParam = 1 }, category: string, optionalToken = ''): Promise<VariantModelList> => {
    if (category === "TRENDING") {
        const { data } = await axios.get(
            `${baseURL}/variant-models?per_page=${results_per_page}&page=${pageParam}&is_trending=true&status=ACTIVE`,
            {
                headers: {
                    "Authorization": `Bearer ${optionalToken || getTokenFromCookie()}`
                }
            });
        return data;
    } else {
        const { data } = await axios.get(
            `${baseURL}/variant-models?per_page=${results_per_page}&page=${pageParam}&category=${category}&status=ACTIVE`,
            {
                headers: {
                    "Authorization": `Bearer ${optionalToken || getTokenFromCookie()}`
                }
            });
        return data;
    }
}

export const useGetVariantModelListByCategory = (category: string, initialData: VariantModelList) => {
    return useInfiniteQuery<VariantModelList, Error>(
        ['variantModels'],
        ({ pageParam = 1 }) => getVariantModelListByCategory({ pageParam }, category),
        {
            getNextPageParam: (pages) => {
                if (pages.data.length > 0) {
                    return pages.page + 1
                } else {
                    return undefined
                }
            },
            initialData: () => {
                const data = initialData
                if (data) {
                    return {
                        pageParams: [undefined],
                        pages: [data]
                    }
                }
            },
            refetchOnMount: false,
        },
    )
}

export const getVariantModelListByBaseModelId = async (base_model_id: string): Promise<VariantModelList> => {
    const { data } = await axios.get(
        `${baseURL}/variant-models?per_page=2&page=1&base_model_id=${base_model_id}`,
        {
            headers: {
                "Authorization": `Bearer ${getTokenFromCookie()}`
            }
        }
    );
    return data;
}

const getVariantModelsByCategory = async (category: string): Promise<VariantModelList> => {

    if (category === "TRENDING") {
        const { data } = await axios.get<VariantModelList>(`${baseURL}/variant-models?list_all=true&status=ACTIVE&is_trending=true`,
            {
                headers: {
                    "Authorization": `Bearer ${getTokenFromCookie()}`
                }
            })

        return data;
    } else {
        const { data } = await axios.get<VariantModelList>(`${baseURL}/variant-models?category=${category}&status=ACTIVE`,
            {
                headers: {
                    "Authorization": `Bearer ${getTokenFromCookie()}`
                }
            })

        return data;
    }
}

export const useGetVariantModelsByCategory = (category: string) => {
    return useQuery<VariantModelList, Error>(['getVariantModelsByCategory', category], () => getVariantModelsByCategory(category));
}

export const useGetVariantModelBySlug = (base_model_slug: string, variant_model_slug: string) => {
    return useQuery<VariantModel, Error>(["getVariantModelBySlug", [base_model_slug, variant_model_slug]], () => getVariantModelBySlug(base_model_slug, variant_model_slug))
}