import React from "react";

import { useEffect } from 'react';
import dynamic from "next/dynamic";

const Div100vh = dynamic(
    () => import('react-div-100vh'),
    { ssr: false }
)

const Dynamic100Vh: React.FC<{ style?: any, delay?: number }> = ({ children, ...props }) => {
    const [isInitial, setIsInitial] = React.useState(true);

    const checkInitialRender = () => {
        if (isInitial) {
            return (
                <div className="initial-container" {...props} style={{height: '100vh'}}>
                    {children}
                </div>
            )
        } else {
            return (
                <Div100vh {...props}>
                    {children}
                </Div100vh>
            )
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setIsInitial(false);
        }
            , props.delay || 0);
    }, [])

    return (
        <>{checkInitialRender()}</>
    );
}

export default Dynamic100Vh;
